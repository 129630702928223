import React, { Component } from "react";
import { Box } from "@material-ui/core";
import { firestore } from '../../firebase';
import LaunchScreen from "../LaunchScreen";

class ArenaImport extends Component {


  constructor(props) {
    super(props);

    this.state = {
      arenas: [],
    }
    this.importArenas();
  }

  createKey = (state, arenaName) => {
    var arenaKey = state + ' ' + arenaName; 
    arenaKey = arenaKey.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()'@]/g,"");
    arenaKey = arenaKey.replace(/[\n\t]/g," ");
    arenaKey = arenaKey.replace(/\s{2,}/g, ' ');
    arenaKey = arenaKey.trim();
    arenaKey = arenaKey.replace(/\s/g, '-');
    arenaKey = arenaKey.toLowerCase();
    return(arenaKey);
}


  importArenas = () => {
    var that = this;
    fetch('data/arenaImport.json')
    .then((r) => r.json())
    .then((arenas) => {
      console.log(arenas);

      arenas.forEach(arena => {
        var arenaKey = that.createKey(arena.state, arena.arena); 
        firestore.collection("centers").doc(arenaKey).update(arena)
        .then(()=>{
          console.log('Successfully updated arena to database with arena key: ', arenaKey);
        })
        .catch((error)=>{
          console.error('Error updating arena to database: ', error);
          console.error(error.message);
          if(error.message.startsWith('No document to update')){
            firestore.collection("centers").doc(arenaKey).set(arena)
            .then(()=>{
              console.log('Successfully added arena to database with arena key: ', arenaKey);
            })
            .catch((error)=>{
              console.error('Error writing new arena to database: ', error);
            });
    
          }
        });
      });

      that.setState({arenas: arenas});

    })
  }

  render() {

    if(!this.state.arenas){
      return(<LaunchScreen />)
    }

    return (
      <Box>
        {JSON.stringify(this.state.arenas)}
      </Box>
    );
  }
}

export default (ArenaImport);
