import React, { Component } from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { firestore, storage } from '../../firebase';
import LaunchScreen from "../LaunchScreen";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2, 1),
    maxWidth: 1000,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  center: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  input: {
    display: 'none',
  },
});

class ArenaImageUpload extends Component {

  constructor(props) {
    super(props);

    const arenaID = this.props.match.params.arenaID; 

    this.state = {
      arena: null,
      arenaID: null,
      downloadURL: '',
      arenaURL: '',
    }

    if(arenaID){
      this.getArena(arenaID);
      console.log('Arena ID: ', arenaID)
    }  

  }

  createKey = (state, arenaName) => {
    var arenaKey = state + ' ' + arenaName; 
    arenaKey = arenaKey.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()'@]/g,"");
    arenaKey = arenaKey.replace(/[\n\t]/g," ");
    arenaKey = arenaKey.replace(/\s{2,}/g, ' ');
    arenaKey = arenaKey.trim();
    arenaKey = arenaKey.replace(/\s/g, '-');
    arenaKey = arenaKey.toLowerCase();
    return(arenaKey);
  }

  getArena = (arenaID) => {
    
    var arenaRef = firestore.collection('centers').doc(arenaID);
    var that = this;

    arenaRef.get().then(function(arena) {
      if (arena.exists) {
          console.log("Arena data:", arena.data());
          var arenaDocument = arena.data()
          that.setState({
            arena: arenaDocument,
            arenaID: arenaID
          });    

      } else {
          console.log("No such arena!");
      }
    }).catch(function(error) {
      console.error("Error getting arena:", error);
    });    

  }  

  handleUpload = (event) => {
    var that = this;
    var file = null;
    var fileName = this.state.arenaID + '.jpg';
    var uploadControl = document.getElementById("imageUploadControl");
    console.log(uploadControl);
    if('files' in uploadControl){
      if(uploadControl.files.length === 0){
        console.log("No files selected...");
      } else {
        file = uploadControl.files[0]
        console.log("File: ", file);
        if(file.type === 'image/png'){
          fileName = this.state.arenaID + '.png';
        }
      }
    } else {
      console.log("No files...");
    }

    // Create the file metadata
    var metadata = {
      contentType: file.type
    };

    var storageRef = storage.ref();

    var imageRef = storageRef.child('arena-images/' + fileName);
    console.log(imageRef);

    imageRef.put(file, metadata)
    .then((snapshot) =>{
      console.log('Uploaded a file...');
      snapshot.ref.getDownloadURL().then(function(downloadURL) {
        console.log('File available at', downloadURL);
        var arenaRef = firestore.collection('centers').doc(that.state.arenaID);
        arenaRef.update({ arenaImageUrl: downloadURL })
        .then(()=>{
          console.log('Updated datastore...');
          var arenaURL = '/arena/' + that.state.arenaID;
          that.setState({downloadURL: downloadURL, arenaURL: arenaURL});
        })
        .catch((error)=>{
          console.error("Error updating datastore: ", error);
        });

      });
    })
    .catch((error) => {
      console.error("Error uploading file", error);
    });

    /*
    uploadTask.on('state_changed', (snapshot) =>{
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log('Upload is paused');
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log('Upload is running');
          break;      
    });
    */
  }



  render() {

    const { classes } = this.props;
    const arena = this.state.arena;

    if(!arena){
      return(<LaunchScreen />)
    }

    return (
      <Box className={classes.root}>
        Image Upload for {arena.arena}...
        <div className={classes.root}>
          <input
            accept="image/*"
            className={classes.input}
            id="imageUploadControl"
            type="file"
            onChange={this.handleUpload}
          />
          <label htmlFor="imageUploadControl">
            <Button variant="contained" color="primary" component="span">
            Upload
            </Button>
          </label>
          {this.state.arenaURL && this.state.downloadURL &&
          <>
          <h2><a href={this.state.arenaURL}>Return to Arena Detail Page: {this.state.arenaURL}</a></h2>
          <h2><a href={this.state.downloadURL} target="_blank" rel="noopener noreferrer">Open Image: {this.state.downloadURL}</a></h2>
          </>
          }
        </div>        
      </Box>
    );
  }
}

ArenaImageUpload.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ArenaImageUpload);
