import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { Box } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
//import Iframe from 'react-iframe'
import { firestore } from '../../firebase';
import GoogleMapReact from 'google-map-react';
//import _ from 'lodash';
import moment from 'moment';
import {Helmet} from 'react-helmet';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2, 1),
    maxWidth: 1000,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  center: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },

  homeLogo: {
    float: 'left',    
  },

  logoImage: {
    height: 140, 
    width: 140,
    padding: 20,
  },

  socialMedia:{
    float: 'right',
    filter: 'invert(50%)',
    padding: 20,
  },
  socialMediaBottom:{
    filter: 'invert(100%)',
    paddingTop: 20,
    paddingBottom: 20,
  },

  clearboth:{
    clear: 'both',
  },

  shareHelpText:{

  },
  socialButton:{
  },
  socialSVG:{
    fontSize: '3rem',
    width: '29px',
  },
  topPadding:{
    height: 105,
  },
  bottomPadding:{
    height: 105,
  },
  whiteLink:{
    color: '#fff',
  },
  stadiumTitle:{
    fontFamily: 'MonumentExtended-Ultrabold, Archivo Black, sans-serif',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#1627ff',    
    fontSize: '2.5rem',
    lineHeight: 1,
  },

  stadiumTeam:{
    color: '#f00',    
  },

  stadiumTitleUnderline:{
    border: '3px solid red',
  },

  redSectionTitle:{
    fontFamily: 'MonumentExtended-Ultrabold, Archivo Black, sans-serif',
    textTransform: 'uppercase',
    fontWeight: 400,
    color: '#f00',    
    fontSize: '2.0rem',
    lineHeight: 1,
  },
  redSectionDetail:{
    fontWeight: 500,
    fontSize: '1.5rem',
    color: '#f00',    
  },
  redSectionFootnote:{
    fontWeight: 400,
    fontSize: '1.2rem',
    fontStyle: 'italic',    
    color: '#f00',    
  },

  redSectionMoreInfo:{
    fontWeight: 400,
    fontSize: '1.2rem',
    color: '#f00',    
  },

  blueSectionTitle:{
    fontFamily: 'MonumentExtended-Ultrabold, Archivo Black, sans-serif',
    textTransform: 'uppercase',
    fontWeight: 400,
    color: '#1627ff',    
    fontSize: '2.0rem',
    lineHeight: 1,
  },
  blueSectionDetail:{
    fontWeight: 500,
    fontSize: '1.5rem',
    color: '#1627ff',    
  },

  blueArenaVotingLink:{
    fontWeight: 300,
    fontSize: '1.5rem',
    color: '#1627ff',    
  },


  arenaImage:{
    width: '100%',
  },
  mapIframe:{
    border: 0,    
  },

  blueBand:{
    backgroundColor: '#1627ff',
    color: '#fff',
  },
  blueBandH2: {
    fontSize: '1.7em',
    fontWeight: 400,
    lineHeight: 1.2,
  },
  redBand:{
    backgroundColor: '#f00',
    color: '#fff',
  },
  redBandH2: {
    fontSize: '1.7em',
    fontWeight: 400,
    lineHeight: 1.2,
  },
  blackBand:{
    backgroundColor: '#000',
    color: '#fff',
  },
  blackBandH2: {
    fontSize: '1.7em',
    fontWeight: 400,
    lineHeight: 1.2,
  },
  redText:{
    color: '#f00',
  },
  arenaVotingLinkContainer:{
    textAlign: 'center',
  },

});

class Arena extends Component {

  constructor(props) {
    super(props);
    console.log(props);
    const arenaID = this.props.match.params.arenaID; 

    this.state = {
      arena: null,
      arenaID: null,
    }

    if(arenaID){
      this.getArena(arenaID);
      console.log('Arena ID: ', arenaID)
    }  
  }


  getArena = (arenaID) => {
    
    var arenaRef = firestore.collection('centers').doc(arenaID);
    var that = this;

    arenaRef.get().then(function(arena) {
      if (arena.exists) {
          console.log("Arena data:", arena.data());
          var arenaDocument = arena.data()
          that.setState({
            arena: arenaDocument,
            arenaID: arenaID
          });    

      } else {
          console.log("No such arena!");
      }
    }).catch(function(error) {
      console.log("Error getting arena:", error);
    });    

  }
  handleApiLoaded = (map, maps, arena) => {
    const markers = [];
    const infowindows = [];

    var latLon = arena.latLong.split(',');
    var arenaLat = parseFloat(latLon[0]);
    var arenaLon = parseFloat(latLon[1]);

    markers.push(new maps.Marker({
        position: {
          lat: arenaLat,
          lng: arenaLon,
        },
        map,
     }));
  
    infowindows.push(new maps.InfoWindow({
        content: arena.arena,
     }));
  
    markers.forEach((marker, i) => {
      marker.addListener('click', () => {
        infowindows[i].open(map, marker);
      });
    });
  }

  newlinesToBR = (text) => {
    text = text.replace(/[\n]/g,"<br>");
    return(text);
  }

  openSharePopup = (event) => {
    event.preventDefault();
    var windowName = 'ShareWindow';
    var dialogWidth = 600;
    var dialogHeight = 500;
    var dialogLeft = (window.screen.width/2)-(dialogWidth/2)
    var dialogTop = (window.screen.height/2)-(dialogHeight)
    window.open(event.currentTarget.href, windowName, 'width=' + dialogWidth + ', height=500, left='+ dialogLeft +', top='+ dialogTop +', scrollbars, resizable'); 
    return(false);
  }
  

  render() {
    // Styling
    const { classes } = this.props;
    const arena = this.state.arena;
    if(!arena){
      return (
        <div className={classes.center}>
          <CircularProgress />
        </div>
      );
    }

    var latLon = arena.latLong.split(',');
    var arenaLat = parseFloat(latLon[0]);
    var arenaLon = parseFloat(latLon[1]);
    var placeHolderArenaImage = 'https://img2.cgtrader.com/items/2099171/b9e44aac95/generic-soccer-stadium-3d-model-obj-mtl-fbx-blend-dae.jpg';
    return (
      <>
      <div className="application">
        <Helmet>
          <title>
            Make History at {arena.arena} {arena.team ? ' - ' + arena.team : ''}
          </title>

          <meta name="description"          content="Make History Here: The Election Super Centers project is working with NFL, NHL, NBA and MLB teams to turn their arenas into Election Super Centers that provide easy access to socially-distanced voting with reduced lines." />
          <meta name="keywords"             content="Make History Here, Election Super Center, Election Super Centers, Election Super Centers Project, NFL, NBA, NHL, MLB, Voting, 2020 Election, 2020 Presidential Election, Bi-Partisan Voting Project"/>
          <meta property="og:url"           content='https://MakeHistoryHere.org' />
          <meta property="og:type"          content="website" />
          <meta property="og:title"         content={"Make History at " + arena.arena + (arena.team ? ' - ' + arena.team : '')} />
          <meta property="og:description"   content="Make History Here: The Election Super Centers project is working with NFL, NHL, NBA and MLB teams to turn their arenas into Election Super Centers that provide easy access to socially-distanced voting with reduced lines." />
          <meta property="og:image"         content={arena.arenaImageUrl} />
          <meta property="fb:app_id"        content='661580947720540' />

          <meta name="twitter:card"         content="summary_large_image" />
          <meta name="twitter:title"        content={"Make History at " + arena.arena + (arena.team ? ' - ' + arena.team : '')} />
          <meta name="twitter:description"  content="Make History Here: The Election Super Centers project is working with NFL, NHL, NBA and MLB teams to turn their arenas into Election Super Centers that provide easy access to socially-distanced voting with reduced lines." />
          <meta name="twitter:image"        content={arena.arenaImageUrl} />

        </Helmet>
      </div>

      <Box>
        <Box className={classes.homeLogo}>
          <a href="/"><img className={classes.logoImage} src="/images/logo.png" alt="Election Super Center" /></a>
        </Box>
        <Box className={classes.socialMedia}>
          <a onClick={this.openSharePopup} className={classes.socialButton + ' socialButton'} href="https://twitter.com/intent/tweet?text=The%20Election%20Super%20Centers%20project%20is%20working%20with%20NFL%2C%20NHL%2C%20NBA%20and%20MLB%20teams%20to%20turn%20their%20arenas%20into%20voting%20centers.&amp;url=https%3A%2F%2Fwww.makehistoryhere.org%2F&amp;hashtags=ElectionSuperCenters,election2020" target="_blank" rel="noopener noreferrer"><svg className={classes.socialSVG} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M22.46 6c-.77.35-1.6.58-2.46.69.88-.53 1.56-1.37 1.88-2.38-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29 0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15 0 1.49.75 2.81 1.91 3.56-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07 4.28 4.28 0 0 0 4 2.98 8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21 16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56.84-.6 1.56-1.36 2.14-2.23z"></path></svg></a>
          &nbsp;<a className={classes.socialButton} href="https://www.instagram.com/electionsupercenters/" target="_blank" rel="noopener noreferrer"><svg className={classes.socialSVG} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8 1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5 5 5 0 0 1-5 5 5 5 0 0 1-5-5 5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3z"></path></svg></a>
          &nbsp;<a onClick={this.openSharePopup} className={classes.socialButton + ' socialButton'} href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://www.makehistoryhere.org/&amp;title=The%20Election%20Super%20Centers%20project%20is%20working%20with%20NFL%2C%20NHL%2C%20NBA%20and%20MLB%20teams%20to%20turn%20their%20arenas%20into%20voting%20centers.&amp;source=LinkedIn" target="_blank" rel="noopener noreferrer"><svg className={classes.socialSVG} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z"></path></svg></a>
          &nbsp;<a onClick={this.openSharePopup} className={classes.socialButton + ' socialButton'} aria-label="Share with Facebook" href="https://www.facebook.com/dialog/share?app_id=661580947720540&amp;href=https%3A%2F%2Fwww.makehistoryhere.org%2F" target="_blank" rel="noopener noreferrer"><svg className={classes.socialSVG} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z"></path></svg></a>
        </Box>

        <Box className={classes.root + ' ' + classes.clearboth}>
        <h1 className={classes.stadiumTitle}>
          Make History<br></br>at {arena.arena}
          {arena.team &&
            <><br></br><span className={classes.stadiumTeam}>{arena.team}</span></>
          }
        </h1>
        <hr className={classes.stadiumTitleUnderline} />
        <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
            <Box>

            
              {arena.arenaImageUrl
              ?
              <img src={arena.arenaImageUrl} alt={arena.arena} className={classes.arenaImage} />
              :
              <a href={"/arenaImageUpload/" + this.state.arenaID}><img src={placeHolderArenaImage} alt={arena.arena} className={classes.arenaImage} /></a>
              }

              <div style={{ height: '450px', width: '100%', border: '1px solid'}}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyA6751hpST7iBKSSHKoQ5uEkXGlqbRgVDk', libraries:['places'], }}
                defaultCenter={{lat: arenaLat, lng: arenaLon}}
                defaultZoom={15}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({map, maps}) => this.handleApiLoaded(map, maps, arena)}
              >
              </GoogleMapReact>
              </div>
              <h3 className={classes.arenaAddress}>Address: {arena.address}</h3>

              <h4 className={classes.redSectionMoreInfo}>Learn more about the candidates and issues on your ballot at <a href="https://www.ballotready.org" target="_blank" rel="noopener noreferrer">www.ballotready.org</a></h4>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            {arena.whoCanVote &&
            <>
            <h2 className={classes.redSectionTitle}>Who can vote here?</h2>
            <h3 className={classes.redSectionDetail}>{arena.whoCanVote}</h3>
            <h4 className={classes.redSectionFootnote}><sup>**</sup>Not sure where you're registered? <a href="https://www.vote.org/am-i-registered-to-vote/" target="_blank" rel="noopener noreferrer">Check here</a></h4>
            </>
            }

            {arena.typeOfVoting &&
            <>
            <h2 className={classes.redSectionTitle}>Type of Voting:</h2>
            <h3 className={classes.redSectionDetail}>
            {arena.typeOfVoting.split('\n').map(function(item, key) {
                return (
                  <span key={key}>
                  {item}
                  <br/>
                  </span>
                )
              })}                          
            </h3>
            </>
            }


            <h2 className={classes.redSectionTitle}>On-Site Registration:</h2>
            {arena.onSiteRegistration ?
            <h3 className={classes.redSectionDetail}>YES</h3>
            :
            <h3 className={classes.redSectionDetail}>NO</h3>
            }


            {arena.votingWindow && false && 
            <>
            <h2 className={classes.blueSectionTitle}>Voting Dates:</h2>
            <h3 className={classes.blueSectionDetail}>

            { moment(arena.votingWindow).isValid() ? 
                <span>{moment(arena.votingWindow.split('T')[0]).format('MM/DD')}</span>
              : 
              <span>
              {arena.votingWindow.split('\n').map(function(item, key) {
                return (
                  <span key={key}>
                  {item}
                  <br/>
                  </span>
                )
                })}
              </span>
              }
            </h3>
            </>
            }

            {arena.votingHours &&
            <>
            <h2 className={classes.blueSectionTitle}>Dates and Hours:</h2>
            <h3 className={classes.blueSectionDetail}>
              {arena.votingHours.split('\n').map(function(item, key) {
                return (
                  <span key={key}>
                  {item}
                  <br/>
                  </span>
                )
                })}
            </h3>
            </>
            }

            {arena.parking &&
            <>
            <h2 className={classes.blueSectionTitle}>On-Site Parking:</h2>
            <h3 className={classes.blueSectionDetail}>{arena.parking}</h3>
            </>
            }

          </Grid>
        </Grid>

        {(arena.boeInfoTeamUrl !== '') &&
        <h4 className={classes.arenaVotingLinkContainer}>
        <a className={classes.blueArenaVotingLink} href={arena.boeInfoTeamUrl} target="_blank" rel="noopener noreferrer">{arena.boeInfoTeamUrl}</a>
        </h4>
        }

        </Box>
        <Box className={classes.bottomPadding}></Box>
        <Box className={classes.blueBand}>
          <Box className={classes.root}>
            <h2 className={classes.blueBandH2}>Are you registered to vote?<br></br><a className={classes.whiteLink} href="https://www.vote.org/am-i-registered-to-vote/" target="_blank" rel="noopener noreferrer">Check your registration status in less than a minute</a></h2>
          </Box>
        </Box>
        <Box className={classes.blackBand}>
        <Box className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <h2 className={classes.blackBandH2}>These stadiums and arenas aren't the only places you can vote. Visit <a className={classes.whiteLink} href="https://vote.org" target="_blank" rel="noopener noreferrer">vote.org</a> to view all your polling locations.</h2>
              <Box className={classes.socialMediaBottom}>
                <a onClick={this.openSharePopup} title="Share on Twitter" className={classes.socialButton + ' socialButton'} href="https://twitter.com/intent/tweet?text=The%20Election%20Super%20Centers%20project%20is%20working%20with%20NFL%2C%20NHL%2C%20NBA%20and%20MLB%20teams%20to%20turn%20their%20arenas%20into%20voting%20centers.&amp;url=https%3A%2F%2Fwww.makehistoryhere.org%2F&amp;hashtags=ElectionSuperCenters,election2020" target="_blank" rel="noopener noreferrer"><svg className={classes.socialSVG} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M22.46 6c-.77.35-1.6.58-2.46.69.88-.53 1.56-1.37 1.88-2.38-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29 0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15 0 1.49.75 2.81 1.91 3.56-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07 4.28 4.28 0 0 0 4 2.98 8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21 16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56.84-.6 1.56-1.36 2.14-2.23z"></path></svg></a>
                &nbsp;<a title="Follow us on Instagram" className={classes.socialButton} href="https://www.instagram.com/electionsupercenters/" target="_blank" rel="noopener noreferrer"><svg className={classes.socialSVG} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8 1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5 5 5 0 0 1-5 5 5 5 0 0 1-5-5 5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3z"></path></svg></a>
                &nbsp;<a onClick={this.openSharePopup} title="Share on LinkedIn" className={classes.socialButton + ' socialButton'} href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://www.makehistoryhere.org/&amp;title=The%20Election%20Super%20Centers%20project%20is%20working%20with%20NFL%2C%20NHL%2C%20NBA%20and%20MLB%20teams%20to%20turn%20their%20arenas%20into%20voting%20centers.&amp;source=LinkedIn" target="_blank" rel="noopener noreferrer"><svg className={classes.socialSVG} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z"></path></svg></a>
                &nbsp;<a onClick={this.openSharePopup} title="Share on Facebook" className={classes.socialButton + ' socialButton'} aria-label="Share with Facebook" href="https://www.facebook.com/dialog/share?app_id=661580947720540&amp;href=https%3A%2F%2Fwww.makehistoryhere.org%2F" target="_blank" rel="noopener noreferrer"><svg className={classes.socialSVG} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z"></path></svg></a>
              </Box>

            </Grid>
            <Grid item xs={6}>
              <h2 className={classes.blackBandH2}>Having trouble registering? Missed the deadline in your state? Visit <a className={classes.whiteLink} href="https://866ourvote.org" target="_blank" rel="noopener noreferrer">866ourvote.org</a> for election protection.</h2>
            </Grid>
          </Grid>
        </Box>
      </Box>

      </Box>
      </>
    );
  }
}

Arena.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Arena);
