import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Box } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import LaunchScreen from "../LaunchScreen";

import { firestore } from '../../firebase';
import GoogleMapReact from 'google-map-react';
import {Helmet} from 'react-helmet';

const styles = (theme) => ({
  center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
  },
  root: {
    padding: theme.spacing(2, 1),
    maxWidth: 1000,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  clearboth:{
    clear: 'both',
  },
  bannerHome: {
    background: 'linear-gradient( rgba(0,0,0,0.5), rgba(0,0,0,0.5) ), url(images/banner.jpg)',
    backgroundSize: 'cover',
    height: 800,
  },
  bannerTitleSection:{
  },
  bannerTitle:{
    fontFamily: 'MonumentExtended-Ultrabold, Archivo Black, sans-serif',
    textTransform: 'uppercase',
    color: '#fff',
    fontSize: '5rem',
    lineHeight: 1.1,
  },
  bannerSubtitle:{
    fontFamily: 'MonumentExtended-Regular, Archivo, sans-serif',
    textTransform: 'uppercase',
    color: '#fff',
    fontSize: '3.2rem',
    lineHeight: '1.0',
  },
  blueBand:{
    backgroundColor: '#1627ff',
    color: '#fff',
    padding: '15px 0px',
  },
  blueBandH2: {
    fontSize: '1.7em',
    fontWeight: 400,
    lineHeight: 1.2,
  },
  gridColumn:{
    padding: '0 12px !important',
  },
  redBand:{
    backgroundColor: '#f00',
    color: '#fff',
  },
  redBandH2: {
    fontSize: '1.7em',
    fontWeight: 400,
    lineHeight: 1.2,
  },
  blackBand:{
    backgroundColor: '#000',
    color: '#fff',
  },
  blackBandH2: {
    fontSize: '1.7em',
    fontWeight: 400,
    lineHeight: 1.2,
  },
  redText:{
    color: '#f00',
  },
  quoteContainer:{
    padding: '0px 80px',
  },
  quoteText: {
    fontFamily: 'MonumentExtended-Regular, Archivo, sans-serif',
    textTransform: 'uppercase',
    //textAlign: 'justify',
    fontSize: '2.2rem',
    lineHeight: '1.0',
  },
  quoteAttrib:{
    textAlign: 'right',
    fontSize: '1.7em',
    fontWeight: 400,
  },
  explainer:{
    fontSize: '2.5em',
    lineHeight: 1.2,
    fontWeight: 400,
  },
  explainerSubTitle:{
    fontSize: '2.7em',
    fontWeight: 800,
  },

  stadiumListSection:{
    color: '#f00',
  },
  stadiumListTitle:{
    fontFamily: 'MonumentExtended-Regular, Archivo, sans-serif',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#1627ff',    
    fontSize: '2.5rem',
  },
  stadiumListTitleUnderline:{
    border: '3px solid red',
  },

  stadiumListDisclaimer:{
    fontSize: '1.5em',
    fontWeight: 400,
  },

  stateTitle:{
    fontFamily: 'MonumentExtended-Regular, Archivo, sans-serif',
    fontWeight: 600,
    textTransform: 'uppercase',
    fontSize: '1.7rem',
    color: '#f00',
  },

  arenaLink:{
    fontSize: '1.3rem',
    fontWeight: 400,
    color: '#f00',
  },  

  whiteLink:{
    color: "#fff",
  },

  mapIframe:{
    border: 0,    
  },

  header:{
    padding: '10px 16px',
    color: '#000',

  },
  homeLogo: {
    float: 'left',    
  },
  homeLogoBottom:{
    float: 'right',    
  },
  logoImage: {
    height: 120, 
    width: 120,
    marginTop: -10,
    //padding: 20,
  },
  bottomPadding:{
    height: 100,
  },

  socialMedia:{
    float: 'right',
    filter: 'invert(100%)',
    padding: 20,
  },
  socialMediaBottom:{
    filter: 'invert(100%)',
    paddingTop: 20,
    paddingBottom: 20,
  },
  shareHelpText:{

  },
  socialButton:{
  },
  socialSVG:{
    fontSize: '3rem',
    width: '29px',
  },

});


class HomePage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      arenaList: [],
    }

    this.getArenaList();
  }


  getArenaList = () => {
    
    var arenaRef = firestore.collection('centers').orderBy('state').orderBy('team');
    var that = this;
    var arenaList = [];
    arenaRef.get().then(function(arenaSnapShot) {
      arenaSnapShot.forEach((arena) => {
        //console.log(arena.id, '=>', arena.data());
        var arenaDocument = arena.data();
        arenaDocument.id = arena.id;
        arenaList.push(arenaDocument);  
      });
      that.setState({arenaList: arenaList});
    }).catch(function(error) {
      console.log("Error getting arena list:", error);
    });    

  }

  handleApiLoaded = (map, maps, arenaList) => {
    const markers = [];
    const infowindows = [];

    arenaList.forEach((arena) =>{
      //console.log(arena);
      if(arena.latLong){
        var latLon = arena.latLong.split(',');
        var arenaLat = parseFloat(latLon[0]);
        var arenaLon = parseFloat(latLon[1]);
    
        markers.push(new maps.Marker({
            position: {
              lat: arenaLat,
              lng: arenaLon,
            },
            map,
        }));
      
        infowindows.push(new maps.InfoWindow({
            content: "<div><h2>" + arena.team + ' - ' + arena.arena + "</h2><p><a href='/arena/" + arena.id + "'>Election Center Info</a></p><div>"
        }));
      }
    });
  
    markers.forEach((marker, i) => {
      marker.addListener('click', () => {
        infowindows[i].open(map, marker);
      });
    });
  }

  openSharePopup = (event) => {
    event.preventDefault();
    var windowName = 'ShareWindow';
    var dialogWidth = 600;
    var dialogHeight = 500;
    var dialogLeft = (window.screen.width/2)-(dialogWidth/2)
    var dialogTop = (window.screen.height/2)-(dialogHeight)
    window.open(event.currentTarget.href, windowName, 'width=' + dialogWidth + ', height=500, left='+ dialogLeft +', top='+ dialogTop +', scrollbars, resizable'); 
    return(false);
  }

  render() {
    const { classes } = this.props;

    var col1 = [];
    var col2 = [];
    var col3 = [];

    var arenaList = this.state.arenaList;
    var arenaCount = arenaList.length;
    if(arenaCount === 0){
      return(<LaunchScreen />);
    }

    for(var i = 0; i < arenaCount; i++){
      if(i < (arenaCount / 3)){
        col1.push(arenaList[i]);
      }  else if(i < (2 * (arenaCount / 3))){
        if(col1[col1.length-1].state === arenaList[i].state){
          col1.push(arenaList[i]);
        } else {
          col2.push(arenaList[i]);
        }
      }  else if(i < arenaCount){

        if(col2[col2.length-1].state === arenaList[i].state){
          col2.push(arenaList[i]);
        } else {
          col3.push(arenaList[i]);
        }

      }  
    }
    //console.log('Col1: ', col1);
    //console.log('Col2: ', col2);
    //console.log('Col3: ', col3);

    return (
      <>
      <div className="application">
        <Helmet>
          <title>
            Make History Here: The Election Super Centers Project
          </title>

          <meta name="description"          content="Make History Here: The Election Super Centers project is working with NFL, NHL, NBA and MLB teams to turn their arenas into Election Super Centers that provide easy access to socially-distanced voting with reduced lines." />
          <meta name="keywords"             content="Make History Here, Election Super Center, Election Super Centers, Election Super Centers Project, NFL, NBA, NHL, MLB, Voting, 2020 Election, 2020 Presidential Election, Bi-Partisan Voting Project"/>
          <meta property="og:url"           content='https://MakeHistoryHere.org' />
          <meta property="og:type"          content="website" />
          <meta property="og:title"         content="Make History Here: Election Super Centers Project" />
          <meta property="og:description"   content="Make History Here: The Election Super Centers project is working with NFL, NHL, NBA and MLB teams to turn their arenas into Election Super Centers that provide easy access to socially-distanced voting with reduced lines." />
          <meta property="og:image"         content="https://firebasestorage.googleapis.com/v0/b/electionsupercenterproject.appspot.com/o/make-history-here-image-new2.jpeg?alt=media&token=2577a748-4e38-438c-b5fb-f3fe7cfe4269" />
          <meta property="fb:app_id"        content='661580947720540' />

          <meta name="twitter:card"         content="summary_large_image" />
          <meta name="twitter:title"        content="Make History Here: Election Super Centers Project" />
          <meta name="twitter:description"  content="Make History Here: The Election Super Centers project is working with NFL, NHL, NBA and MLB teams to turn their arenas into Election Super Centers that provide easy access to socially-distanced voting with reduced lines." />
          <meta name="twitter:image"        content="https://firebasestorage.googleapis.com/v0/b/electionsupercenterproject.appspot.com/o/make-history-here-image-new2.jpeg?alt=media&token=2577a748-4e38-438c-b5fb-f3fe7cfe4269" />

        </Helmet>
      </div>

      <Box className={classes.bannerHome}>

        <Box hidden className={classes.homeLogo}>
          <img className={classes.logoImage} src="images/logo.png" alt="Election Super Center" />
        </Box>
        <Box className={classes.socialMedia}>
          <a onClick={this.openSharePopup} title="Share on Twitter" className={classes.socialButton + ' socialButton'} href="https://twitter.com/intent/tweet?text=The%20Election%20Super%20Centers%20project%20is%20working%20with%20NFL%2C%20NHL%2C%20NBA%20and%20MLB%20teams%20to%20turn%20their%20arenas%20into%20voting%20centers.&amp;url=https%3A%2F%2Fwww.makehistoryhere.org%2F&amp;hashtags=ElectionSuperCenters,election2020" target="_blank" rel="noopener noreferrer"><svg className={classes.socialSVG} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M22.46 6c-.77.35-1.6.58-2.46.69.88-.53 1.56-1.37 1.88-2.38-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29 0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15 0 1.49.75 2.81 1.91 3.56-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07 4.28 4.28 0 0 0 4 2.98 8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21 16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56.84-.6 1.56-1.36 2.14-2.23z"></path></svg></a>
          &nbsp;<a title="Follow us on Instagram" className={classes.socialButton} href="https://www.instagram.com/electionsupercenters/" target="_blank" rel="noopener noreferrer"><svg className={classes.socialSVG} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8 1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5 5 5 0 0 1-5 5 5 5 0 0 1-5-5 5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3z"></path></svg></a>
          &nbsp;<a onClick={this.openSharePopup} title="Share on LinkedIn" className={classes.socialButton + ' socialButton'} href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://www.makehistoryhere.org/&amp;title=The%20Election%20Super%20Centers%20project%20is%20working%20with%20NFL%2C%20NHL%2C%20NBA%20and%20MLB%20teams%20to%20turn%20their%20arenas%20into%20voting%20centers.&amp;source=LinkedIn" target="_blank" rel="noopener noreferrer"><svg className={classes.socialSVG} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z"></path></svg></a>
          &nbsp;<a onClick={this.openSharePopup} title="Share on Facebook" className={classes.socialButton + ' socialButton'} aria-label="Share with Facebook" href="https://www.facebook.com/dialog/share?app_id=661580947720540&amp;href=https%3A%2F%2Fwww.makehistoryhere.org%2F" target="_blank" rel="noopener noreferrer"><svg className={classes.socialSVG} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z"></path></svg></a>
        </Box>

        <Box className={classes.root}>
          <Box id="bannerTitleSection" className={classes.bannerTitleSection}>
            <h1 className={classes.bannerTitle}>Make History Here</h1>
            <h2 className={classes.bannerSubtitle}>Vote Safely At Sports Arenas Across The Country</h2>
          </Box>
        </Box>
      </Box>
      <Box className={classes.blueBand}>
        <Box className={classes.root}>
          <span className={classes.homeLogoBottom}><img id="logoImage" className={classes.logoImage} src="images/logo.png" alt="Election Super Center" /></span>
          <h2 className={classes.blueBandH2}>Are you registered to vote?<br></br><a className={classes.whiteLink} href="https://www.vote.org/am-i-registered-to-vote/" target="_blank" rel="noopener noreferrer">Check your registration status in less than a minute</a></h2>
        </Box>
      </Box>
      <Box>
        <Box className={classes.root}>
          <Box className={classes.redText}>
            <Box className={classes.quoteContainer}>
              <h2 className={classes.quoteText}>"You're used to going to these arenas to cheer on your favorite team. Now you can use these spaces to really make your voice heard in an even more profound way, by exercising your right to vote."</h2>
              <h3 className={classes.quoteAttrib}>-Doc Rivers, Head Coach, Philadelphia 76ers</h3>
            </Box>
            <h2 className={classes.explainer}>Now, the stadiums and arenas listed below are opening their doors for safe, socially-distanced voting.</h2>
            <h2 className={classes.explainerSubTitle}>Social-distancing. Short lines. Easy access.</h2>
          </Box>
        </Box>
      </Box>

      <Box className={classes.redBand}>
        <Box className={classes.root}>
          <h2 className={classes.redBandH2}>These aren't the only places you can vote safely, early, and on November 3rd.<br></br>Vist <a className={classes.whiteLink} href="https://vote.org" target="_blank" rel="noopener noreferrer">vote.org</a> to view all your polling locations.</h2>
        </Box>
      </Box>

      <Box className={classes.stadiumListSection}>
        <Box className={classes.root}>
          <h2 className={classes.stadiumListTitle}>Stadiums &amp; Arenas</h2>
          <hr className={classes.stadiumListTitleUnderline}></hr>
          <h3 className={classes.stadiumListDisclaimer}>Click venue name below for details on who can vote there, when and how.</h3>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} className={classes.gridColumn}>
              { col1.map((arena, index) => (
                <div key={index}>
                { index === 0 &&
                  <h2 className={classes.stateTitle}>{arena.state}</h2>
                }
                { index > 0 && col1[index].state !== col1[index-1].state && 
                  <h2 className={classes.stateTitle}>{arena.state}</h2>
                }
                { arena.team 
                ?
                <h4 className={classes.arenaLinkContainer}>
                  <a className={classes.arenaLink} href={"/arena/" + arena.id}>{arena.team}: {arena.arena}</a>
                </h4>
                :
                <h4 className={classes.arenaLinkContainer}>
                  <a className={classes.arenaLink} href={"/arena/" + arena.id}>{arena.arena}</a>
                </h4>
                }
                </div>
              ))}
            </Grid>
            <Grid item xs={12} sm={4} className={classes.gridColumn}>
            { col2.map((arena, index) => (
                <div key={index}>
                { index === 0 &&
                  <h2 className={classes.stateTitle}>{arena.state}</h2>
                }
                { index > 0 && col2[index].state !== col2[index-1].state && 
                  <h2 className={classes.stateTitle}>{arena.state}</h2>
                }
                { arena.team 
                ?
                <h4 className={classes.arenaLinkContainer}>
                  <a className={classes.arenaLink} href={"/arena/" + arena.id}>{arena.team}: {arena.arena}</a>
                </h4>
                :
                <h4 className={classes.arenaLinkContainer}>
                  <a className={classes.arenaLink} href={"/arena/" + arena.id}>{arena.arena}</a>
                </h4>
                }

                </div>
             ))}
            </Grid>
            <Grid item xs={12} sm={4} className={classes.gridColumn}>
            { col3.map((arena, index) => (
                <div key={index}>
                { index === 0 &&
                  <h2 className={classes.stateTitle}>{arena.state}</h2>
                }
                { index > 0 && col3[index].state !== col3[index-1].state && 
                  <h2 className={classes.stateTitle}>{arena.state}</h2>
                }
                { arena.team 
                ?
                <h4 className={classes.arenaLinkContainer}>
                  <a className={classes.arenaLink} href={"/arena/" + arena.id}>{arena.team}: {arena.arena}</a>
                </h4>
                :
                <h4 className={classes.arenaLinkContainer}>
                  <a className={classes.arenaLink} href={"/arena/" + arena.id}>{arena.arena}</a>
                </h4>
                }

                </div>
            ))}
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box className={classes.bottomPadding}></Box>

      <Box className={classes.stadiumMapSection}>

        <div style={{ height: '750px', width: '100%'}}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyA6751hpST7iBKSSHKoQ5uEkXGlqbRgVDk', libraries:['places'], }}
                defaultCenter={{lat: 37.8283, lng: -98.5795}}
                defaultZoom={5}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({map, maps}) => this.handleApiLoaded(map, maps, arenaList)}
              >
              </GoogleMapReact>
        </div>



      </Box>
      <Box className={classes.blueBand}>
        <Box className={classes.root}>
          <h2 className={classes.blueBandH2}>Not all venues accept voters from all precincts. Click above for details.<br></br>Or vist <a className={classes.whiteLink} href="https://vote.org" target="_blank" rel="noopener noreferrer">vote.org</a> to view all your polling locations.</h2>
        </Box>
      </Box>
      <Box className={classes.blackBand}>
        <Box className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <h2 className={classes.blackBandH2}>These stadiums and arenas aren't the only places you can vote. Vist <a className={classes.whiteLink} href="https://vote.org" target="_blank" rel="noopener noreferrer">vote.org</a> to view all your polling locations.</h2>

              <Box className={classes.socialMediaBottom}>
                <a onClick={this.openSharePopup} title="Share on Twitter" className={classes.socialButton + ' socialButton'} href="https://twitter.com/intent/tweet?text=The%20Election%20Super%20Centers%20project%20is%20working%20with%20NFL%2C%20NHL%2C%20NBA%20and%20MLB%20teams%20to%20turn%20their%20arenas%20into%20voting%20centers.&amp;url=https%3A%2F%2Fwww.makehistoryhere.org%2F&amp;hashtags=ElectionSuperCenters,election2020" target="_blank" rel="noopener noreferrer"><svg className={classes.socialSVG} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M22.46 6c-.77.35-1.6.58-2.46.69.88-.53 1.56-1.37 1.88-2.38-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29 0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15 0 1.49.75 2.81 1.91 3.56-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07 4.28 4.28 0 0 0 4 2.98 8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21 16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56.84-.6 1.56-1.36 2.14-2.23z"></path></svg></a>
                &nbsp;<a title="Follow us on Instagram" className={classes.socialButton} href="https://www.instagram.com/electionsupercenters/" target="_blank" rel="noopener noreferrer"><svg className={classes.socialSVG} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8 1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5 5 5 0 0 1-5 5 5 5 0 0 1-5-5 5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3z"></path></svg></a>
                &nbsp;<a onClick={this.openSharePopup} title="Share on LinkedIn" className={classes.socialButton + ' socialButton'} href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://www.makehistoryhere.org/&amp;title=The%20Election%20Super%20Centers%20project%20is%20working%20with%20NFL%2C%20NHL%2C%20NBA%20and%20MLB%20teams%20to%20turn%20their%20arenas%20into%20voting%20centers.&amp;source=LinkedIn" target="_blank" rel="noopener noreferrer"><svg className={classes.socialSVG} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z"></path></svg></a>
                &nbsp;<a onClick={this.openSharePopup} title="Share on Facebook" className={classes.socialButton + ' socialButton'} aria-label="Share with Facebook" href="https://www.facebook.com/dialog/share?app_id=661580947720540&amp;href=https%3A%2F%2Fwww.makehistoryhere.org%2F" target="_blank" rel="noopener noreferrer"><svg className={classes.socialSVG} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z"></path></svg></a>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <h2 className={classes.blackBandH2}>Having trouble registering? Missed the deadline in your state? Visit <a className={classes.whiteLink} href="https://866ourvote.org" target="_blank" rel="noopener noreferrer">866ourvote.org</a> for election protection.</h2>
            </Grid>
          </Grid>
        </Box>
      </Box>
      </>
    );
  }

}

HomePage.propTypes = {
  user: PropTypes.object,
};

export default withRouter(withStyles(styles)(HomePage));
